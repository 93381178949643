import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

export default function About() {
  return (
    <Layout>
      <SEO
        title="About"
        description="Our passion is our designs, and how each one is totally unique in colour, style and product"
      />
      <main className="w-full flex-grow max-w-screen-xl mx-auto py-4 px-4-safe-area sm:px-6-safe-area" role="main">
        <h1 className="text-3xl pt-1 pb-2 md:text-4xl md:pb-4 md:pt-5 lg:pt-6">About</h1>
        <div className="text-md">
        <p className="mb-6 mt-3 text-2xl">Welcome to ColourStormArt.</p>
        <p className="mb-3">Our passion is our designs, and how each one is totally unique in colour, style and product.</p>
        <p className="mb-3">My main inspiration is nature’s formations, which are reflected in all my paintings.</p>
        <p className="mb-3">I love seeing every client's reaction to their artwork, be it a set of coasters, a beautiful drinks tray or a piece of wall art or table.</p>
        <p className="mb-3">We have slowly built up the business over the last 3 years, with many repeat customers often commissioning projects for their whole house.</p>
        <p className="mb-3">Art has always been my passion, my life and my background and I would feel empty without it. ColourStormArt is a family run business, and we hope to continue making your homes more beautiful and unique.</p>
        <p className="mb-3">Don’t hesitate to <Link className="underline" to="/contact/">contact us</Link> for any more information.</p>
        <p className="mb-3">Emma &amp; Jack @ ColourStormArt</p>
        </div>
      </main>
    </Layout>
  )
}